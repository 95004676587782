const prefix = 'COURSE_WEB_APP';
/**
 * app存储key值
 */
export const STORAGE_TOKEN = 'GXJT_TOKEN';
export const STORAGE_USER_INFO = 'GXJT_USER_INFO';

export const STORAGE_RESULT_INFO = 'GXJT.RESULT_INFO';

/**
 * app全站常量
 */
export const APP_API_PREFIX = process.env.VUE_APP_API_PREFIX.toString(); // api请求
export const APP_TIMEOUT = 20000;
/**
 * course存储key
 */

export const STORAGE_CURRENT_BOOK = `${prefix}.CURRENT_BOOK`;
export const STORAGE_HOME_TAB = `${prefix}.HOME_TAB`;
export const STORAGE_UNIT_INDEX = `${prefix}.UNIT_INDEX`;
export const STORAGE_PAGE_CONTENT = `${prefix}.PAGE_CONTENT`;
