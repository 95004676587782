import Vue from 'vue';
import store from '@/store';
import router from '@/router/index';
import { projMode } from '@/config/index';
import '@/assets/js/flexible';
const varColor = require('@/styles/varHelper/_var.scss');
import '@/router/permission';
import App from './App.vue';
import message from '@/utils/src/message';
import apis from '@/api/src/api';
import * as filters from '@/filters';
import * as tool from '@/utils/src/tools';
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
Vue.config.devtools = projMode !== 'productionn';
Vue.prototype.$msg = message;
Vue.prototype.$apis = apis;
for (const key in filters) {
  Vue.filter(key, filters[key]);
}
import './appInit';
Vue.config.productionTip = false;
Vue.prototype.$varColor = varColor;
tool.showDebugBuyUrl();
const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
