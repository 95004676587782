// 首页
export const PATH_ROOT = '/';
export const PATH_HOME = '/home';
export const PATH_RANK_LIST = '/rangList';
export const PATH_EXAMS = '/exams';
export const PATH_RESULT_INFO = '/resultInfo';
export const PATH_QUESTIONNAIRE = '/questionnaire';
export const PATH_VOTE = '/vote';

// user
export const PATH_LOGIN = '/login';
