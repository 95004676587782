<!--NoData  没有数据-->
<template>
  <div class="tc pb20 NoData">
    <img class="noImg" :src="item.img" alt="" />
    <div class="desc">{{desc || item.desc }}</div>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    type: {
      type: String,
      default: 'content'
    },
    desc: String

  },
  data() {
    return {
      list: {
        content: {
          type: 'content',
          desc: '暂无内容',
          img: require('@/assets/img/noData/noContent.png')
        }
        // mall: {
        //   type: 'mall',
        //   desc: '暂无商品',
        //   img: require('@/assets/img/onData/noMall.png')
        // },
        // coupon: {
        //   type: 'coupon',
        //   desc: '暂无优惠券',
        //   img: require('@/assets/img/onData/noCoupon.png')
        // },
        // movie: {
        //   type: 'movie',
        //   desc: '暂无影片',
        //   img: require('@/assets/img/onData/noMovie.png')
        // },
        // movieCard: {
        //   type: 'movieCard',
        //   desc: '暂无电影卡',
        //   img: require('@/assets/img/onData/noMovieCard.png')
        // },
        // comment: {
        //   type: 'comment',
        //   desc: '暂无评论',
        //   img: require('@/assets/img/onData/noComment.png')
        // },
        // order: {
        //   type: 'order',
        //   desc: '暂无订单',
        //   img: require('@/assets/img/onData/onOrder.png')
        // },
        // scheduling: {
        //   type: 'scheduling',
        //   desc: '暂无排期',
        //   img: require('@/assets/img/onData/noScheduling.png')
        // },
        // news: {
        //   type: 'news',
        //   desc: '暂无消息',
        //   img: require('@/assets/img/onData/noNews.png')
        // },
        // collection: {
        //   type: 'collection',
        //   desc: '暂无收藏',
        //   img: require('@/assets/img/onData/noCollection.png')
        // },
        // signal: {
        //   type: 'signal',
        //   desc: '无信号',
        //   img: require('@/assets/img/onData/noSignal.png')
        // },
        // integral: {
        //   type: 'integral',
        //   desc: '暂无积分记录',
        //   img: require('@/assets/img/onData/noIntegral.png')

        // },
        // award: {
        //   type: 'award',
        //   desc: '暂无奖品记录',
        //   img: require('@/assets/img/onData/noAward.png')

        // },
        // cinema: {
        //   type: 'cinema',
        //   desc: '暂无影院',
        //   img: require('@/assets/img/onData/noCinema.png')

        // },
        // activity: {
        //   type: 'activity',
        //   desc: '暂无活动',
        //   img: require('@/assets/img/onData/noActivity.png')

        // }
      }
    };
  },
  computed: {
    item() {
      return this.list[this.type] || this.list.content;
    }
  },
  methods: {
    // 初始化方法
    init() { }
  }
};
</script>
<style lang="scss" scoped>
.noImg {
  width: 2.2rem;
  height: 2.2rem;
}
.desc {
  color: #969799;
  font-size: 0.32rem;
  margin-top: 0rem;
}
</style>
