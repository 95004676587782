'use strict';

import request from '../request';
export default {
  login(data) {
    return request.post(`/login`, data);
  },
  getRank(params) {
    return request.get(`/exams/rank`, params);
  },
  examsAnswersSubmit(data) {
    return request.post(`/exams/answers/submit`, data);
  },
  examsStart(data) {
    return request.post(`/exams/start`, data);
  },
  examsResult(params) {
    return request.get(`/exams/result`, params);
  },
  // 获取投票信息
  getVoteDetail(id) {
    return request.get(`/voting/${id}`);
  },
  // 不同意
  againstVote(id, data) {
    return request.post(`/voting/${id}/against`, data);
  },
  // 同意
  agreeVote(id, data) {
    return request.post(`/voting/${id}/agree`, data);
  },
  // 弃权
  waiverVote(id, data) {
    return request.post(`/voting/${id}/waiver`, data);
  },
  // 获取问卷调查信息
  getQuestionnaireDetail(id) {
    return request.get(`/questionnaire/${id}`);
  },
  // 获取问卷调查信息
  questionnaireVote(data) {
    return request.post(`/questionnaire/vote`, data);
  }
};
