import { v4 as uuidv4 } from 'uuid';

function getUuid() {
  let uuid = localStorage.getItem('GXJT.uuid');
  if (!uuid) {
    uuid = uuidv4();
    localStorage.setItem('GXJT.uuid', uuid);
  }
  return uuid;
}


export { getUuid };
