import * as auth from '@/utils/auth';
import { createScript } from '@/utils/src/tools';
// import api from '@/api/src/common';
import * as tools from '@/utils/src/tools';
import config, { projMode } from '@/config';
// export const initSDK = () => {
//   const js = {
//     src: `//res.wx.qq.com/open/js/jweixin-1.4.0.js`
//   };
//   return createScript(js).then(() => {
//     console.log('initSDK');
//     return wxConfig();
//   });
// };
export const isWxClient = (): boolean => {
  const ua = window.navigator.userAgent.toLowerCase();
  const match = ua.match(/MicroMessenger/i);
  if (match === null) {
    return false;
  }
  if (match.includes('micromessenger')) {
    return true;
  }
  return false;
};
// export const wxConfig = () => {
//   const wxReady = auth.sLoc.get('wxReady');
//   if (wxReady === '1') {
//     return Promise.resolve();
//   }
//   let appInfo = auth.loc.get('appInfo', {});
//   const wxConfigDesc = {
//     title: appInfo.name, // 分享标题
//     desc: '课本同步，提升孩子听说读写能力',
//     // link: 'http://testfront.lustudy.cn/?timestamp=1231312#/home?appId=18AAE0A2&vs=xiaomi&debug=debug',
//     link: tools.handleSearchCodeUrl(window.location.href),
//     imgUrl: `${appInfo.icon || appInfo.cover}?x-oss-process=image/resize,m_pad,w_200,m_lfit,color_b4ddff` // 分享图标
//   };
//   return new Promise<void>((resolve, reject) => {
//     api
//       .wxSignature({
//         appInfoCode: config.h5InfoCode,
//         appInfoId: config.h5InfoCode,
//         url: window.location.href
//       })
//       .then(data => {
//         // console.log('signature', data);
//         window.wx.config({
//           debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
//           appId: data.appid, // 必填，公众号的唯一标识
//           timestamp: data.timestamp, // 必填，生成签名的时间戳
//           nonceStr: data.nonceStr, // 必填，生成签名的随机串
//           signature: data.signature, // 必填，签名
//           jsApiList: [
//             'getLocation',
//             'chooseWXPay',
//             'scanQRCode',
//             'updateAppMessageShareData',
//             'updateTimelineShareData',
//             'onMenuShareAppMessage',
//             'onMenuShareTimeline'
//           ] // 必填，需要使用的JS接口列表
//         });
//       });
//     window.wx.ready(() => {
//       console.log('wx ready 成功');
//       auth.sLoc.set('wxReady', '1');
//       window.wx.hideMenuItems({
//         menuList: [
//           'menuItem:copyUrl',
//           'menuItem:editTag',
//           'menuItem:delete',
//           'menuItem:originPage',
//           'menuItem:readMode',
//           'menuItem:openWithQQBrowser',
//           'menuItem:openWithSafari',
//           'menuItem:share:email',
//           'menuItem:share:brand',
//           'menuItem:share:qq',
//           'menuItem:share:QZone'
//         ] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮
//       });
//       window.wx.onMenuShareTimeline({
//         ...wxConfigDesc,
//         success: function(res) {
//           console.log(res, '分享成功');
//         },
//         fail: function(res) {
//           console.log(res, '分享失败');
//         }
//       });
//       window.wx.onMenuShareAppMessage({
//         ...wxConfigDesc,
//         success: function(res) {
//           console.log(res, '分享成功');
//         },
//         fail: function(res) {
//           console.log(res, '分享失败');
//         }
//       });
//       resolve();
//     });

//     window.wx.error(function(res) {
//       console.log(res, 'wxconfig 失败');
//       reject();
//     });
//   });
// };
// export function wxFirstLogin() {
//   // const params = tools.param2Obj();
//   // const { code } = params;
//   // if (code) {
//   //   const locCode = auth.sLoc.get('code') || '';
//   //   if (locCode && locCode === code) {
//   //     return;
//   //   }
//   // } else {
//   return api
//     .weixinLoginUrl({
//       appInfoCode: config.h5InfoCode,
//       url: tools.handleSearchCodeUrl(window.location.href)
//     })
//     .then(res => {
//       window.location.href = res;
//     });
// }
// }
// export function wxAuthorization() {
//   return new Promise<void>((resolve, reject) => {
//     const params = tools.param2Obj();
//     console.log('执行微信授权');
//     console.log(params, 'params');
//     const { code } = params;
//     if (code) {
//       const locCode = auth.sLoc.get('code') || '';
//       console.log(locCode, code);
//       if (locCode && locCode === code) {
//         console.log('code 重复用');
//         resolve();
//         return;
//       }
//       auth.sLoc.set('code', code);
//       api
//         .weixinH5Login({ code: auth.sLoc.get('code'), appInfoCode: config.h5InfoCode, id: config.h5Id })
//         .then(res => {
//           console.log('res', res);
//           resolve(res);
//         })
//         .catch(() => {
//           reject();
//         });
//     }
//   });
// }
// export const wxPay = async data => {
//   console.log('调用新的wxpay', data);
//   await tools.getWidowValue('WeixinJSBridge');
//   return new Promise((resolve, reject) => {
//     window.WeixinJSBridge.invoke('getBrandWCPayRequest', data, function(res) {
//       if (res.err_msg === 'get_brand_wcpay_request:ok') {
//         console.log('支付成功', res);
//         resolve(res);
//       } else {
//         console.log('支付失败', res);
//         reject(res);
//       }
//     });
//   });
// };
