'use strict';
import store2 from '../../store2';
import { app } from '../mutationType';
import * as storageKey from '@/config/static';
// import { codes } from '@/api/request/requestCode';
import apis from '@/api/src/api';
// import { alertConfirm } from '@/utils/src/utilsAlert';
// import router from '@/router';
// import * as path from '@/router/routes/routePath';
// import * as auth from '@/utils/auth';
export default {
  namespaced: true,
  state: {
    userInfo: store2.get(storageKey.STORAGE_USER_INFO) || { company: '', name: '', phone: '' },
    token: store2.get(storageKey.STORAGE_TOKEN) || { userAvatar: null },
    resultInfo: store2.get(storageKey.STORAGE_RESULT_INFO) || {
      answerScore: { duration: 0 },
      practiceScore: { duration: 0 }
    }
  },
  actions: {
    setResultInfo({ commit }, info) {
      commit(app.SET_RESULT_INFO, info);
    },
    setUserInfo({ commit }, info) {
      commit(app.SET_USER_INFO, info);
    },
    async login({ commit }, userInfo) {
      return apis.login(userInfo).then(res => {
        commit(app.SET_TOKEN, res.accessToken);
        return res;
      });
    }
  },
  mutations: {
    [app.SET_TOKEN](state: any, res) {
      store2.set(storageKey.STORAGE_TOKEN, res);
      state.token = res;
    },
    [app.SET_RESULT_INFO](state: any, res) {
      store2.set(storageKey.STORAGE_RESULT_INFO, res);
      state.resultInfo = res;
    },
    [app.SET_USER_INFO](state: any, res) {
      store2.set(storageKey.STORAGE_USER_INFO, res);
      state.userInfo = res;
    }
  }
};
