<template>
  <div id="app">
    <div class="app-inner">
      <router-view />
    </div>
  </div>
</template>

<script>
import * as auth from '@/utils/auth';
export default {
  name: 'App',
  components: {},
  data() {
    return {};
  },
  created() {
    const vm = this;
  },
  mounted() {},
  methods: {
    getDeviceInfo() {
      window.plus.device.getInfo({
        success: function(e) {
          console.log('device', JSON.stringify(e));
          auth.loc.set('device_uuid', e.uuid);
        },
        fail: function(e) {
          console.log('getDeviceInfo failed: ' + JSON.stringify(e));
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import '@/styles/varHelper/_var.scss';
#app {
  height: 100%;
  overflow: hidden;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  background: $--color-primary-light-10;
  .app-inner {
    height: 100%;
    overflow: hidden;
  }
}
</style>
