'use strict';

// app
const app = {
  SET_RESULT_INFO: 'set_result_info',
  SET_USER_INFO: 'set_user_info',
  SET_TOKEN: 'set_token'
};

export { app };
