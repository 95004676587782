<template>
  <div
    :style="styleExternalIcon"
    class="svg-external-icon zm-icon"
    v-if="isExternal"
    v-on="$listeners"
  />
  <svg
    :class="svgClass"
    aria-hidden="true"
    v-else
    v-on="$listeners"
  >
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
const isExternal = (path) => {
  return /^(https?:|mailto:|tel:)/.test(path);
};
export default {
  name: 'ZmIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    isExternal() {
      return isExternal(this.iconClass);
    },
    iconName() {
      return `#icon-${this.iconClass}`;
    },
    svgClass() {
      if (this.className) {
        return 'zm-icon ' + this.className;
      } else {
        return 'zm-icon';
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.iconClass}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${this.iconClass}) no-repeat 50% 50%`
      };
    }
  }
};
</script>
<style lang="scss">
.van-cell {
  .zm-icon-box {
    font-size: 0.36rem;
    height: 0.48rem;
    line-height: 0.48rem;
  }
}
</style>
<style scoped>
.zm-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
</style>
