import router from './';
import nprogress from '@/utils/nprogress';
import qs from 'qs';
import { appGetToken } from '@/utils';
import { PATH_LOGIN, PATH_QUESTIONNAIRE, PATH_VOTE } from '@/router/routes/routePath';
import * as auth from '@/utils/auth';
const whiteList = [PATH_LOGIN, PATH_QUESTIONNAIRE, PATH_VOTE];
router.beforeEach(async (to, from, next) => {
  const token = appGetToken();
  console.log('token', token, to, to.path);
  to.query.examId && auth.loc.set('examId', to.query.examId);
  try {
    if (whiteList.includes(to.path)) {
      next();
    } else if (!token) {
      next(PATH_LOGIN);
    } else {
      next();
    }
  } catch (error) {
    console.log(error);
    next();
  }
});

router.onError(error => {
  const pattern = /chunk (.)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

router.afterEach((to, from) => {
  // document.title = to.meta && to.meta.title ? to.meta.title : '';
  let pathObj = to.query;
  if (pathObj.token) delete pathObj.token;
  const pathSearch = qs.stringify(pathObj);
  nprogress.done();
});
