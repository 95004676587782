import { lazyComponent } from '@/utils';
import * as path from './routePath';
export default [
  {
    // 趣味问答
    path: path.PATH_HOME,
    meta: { title: '首页', keep: true },
    component: lazyComponent('home')
  },
  {
    path: path.PATH_LOGIN,
    meta: { title: '登陆', keep: true },
    component: lazyComponent('login')
  },
  {
    path: path.PATH_RANK_LIST,
    meta: { title: '排行榜', keep: true },
    component: lazyComponent('rankList')
  },
  {
    path: path.PATH_RESULT_INFO,
    meta: { title: '历史成绩', keep: true },
    component: lazyComponent('resultInfo')
  },
  {
    path: path.PATH_QUESTIONNAIRE,
    meta: { title: '问卷调查', keep: false },
    component: lazyComponent('questionnaire')
  },
  {
    path: path.PATH_VOTE,
    meta: { title: '表决', keep: false },
    component: lazyComponent('vote')
  }
];
