import {
    Button,
    Field,
    Cell,
    CellGroup,
    Icon,
    Popup,
    Row,
    Col,
    // Picker,
    // NavBar,
    Tabbar,
    TabbarItem,
    PullRefresh,
    // Swipe,
    // SwipeItem,
    Lazyload,
    Image,
    // PasswordInput,
    // NumberKeyboard,
    // Tag,
    Tab,
    Tabs,
    // Sticky,
    ActionSheet,
    IndexBar,
    // IndexAnchor,
    // Grid,
    // GridItem,
    // DatetimePicker,
    Dialog,
    // Uploader,
    List,
    // CountDown,
    // Skeleton,
    // Collapse,
    // CollapseItem,
    CheckboxGroup,
    Checkbox,
    RadioGroup,
    Radio,
    // Form,
    // Row,
    Loading,
    Notify,
    DropdownMenu,
    DropdownItem,
    Slider,
    // Col,
    Divider,
    ShareSheet
  } from 'vant';

  export const vantUI = (Vue) => {
    Vue.use(Button)
      .use(Field)
      .use(CellGroup)
      .use(Cell)
      .use(Icon)
      .use(Popup)
      .use(Row)
      .use(Col)
      .use(Loading)
      // .use(Picker)
      // .use(NavBar)
      .use(Tabbar)
      .use(TabbarItem)
      .use(PullRefresh)
      // .use(Swipe)
      // .use(SwipeItem)
      .use(Lazyload)
      .use(Image)
      // .use(PasswordInput)
      // .use(NumberKeyboard)
      // .use(Tag)
      .use(Tab)
      .use(Tabs)
      // .use(Sticky)
      .use(ActionSheet)
      .use(IndexBar)
      // .use(IndexAnchor)
      // .use(Grid)
      // .use(GridItem)
      // .use(DatetimePicker)
      .use(Dialog)
      // .use(Uploader)
      .use(List)
      // .use(CountDown)
      // .use(Skeleton)
      // .use(Collapse)
      // .use(CollapseItem)
      .use(CheckboxGroup)
      .use(Checkbox)
      .use(Radio)
      .use(RadioGroup)
      // .use(Form)
      // .use(Row)
      .use(Notify)
      .use(ShareSheet)
      .use(DropdownMenu)
      .use(Divider)
      .use(Slider)
      .use(DropdownItem);

      // .use(Col);
  };
